import {
    ILoginNotificationFrequencyOption,
    IUser,
    IUserFormData,
    LoginNotificationFrequecies,
} from "../../data/services/user.types";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    useNotifyUserOptions,
    useUpdateUser,
    useUserNestedFields,
    useUserProfile,
} from "../../data/services/user";

import FormCheckbox from "../FormCheckbox";
import FormInput from "../FormInput";
import FormSelectField from "../FormSelectField";
import useEnhancedForm, {
    SelectOptionsPair,
} from "../../hooks/useEnhancedForm";
import { useIsSuperAdmin } from "../../data/gateway";
import { useCompanyOverride } from "../CompanyOverrideSelect";
import fileHash from "../../data/fileHash";
import { signedFileUpload } from "../../data/services/signedFileUpload";
import FileDrop, { IMAGE_TYPES } from "../FileDrop";
import { ToastContainer, toast } from "react-toastify";
import { useAllPermissionSet } from "../../data/services/permissionSet";
import { useCompany } from "../../data/services/companies";

interface Props {
    action?: "add" | "edit";
    onCancel: () => void;
    onSuccess: () => void;
    initialData?: IUser;
}

function processFormData(data?: IUserFormData): IUser | undefined {
    if (data == null) return undefined;

    return data;
}
// const idLens = (selected: any) => selected.id;
// const nameLens = (selected: any) =>
//     selected.firstName.concat(" ", selected.lastName, " - ", selected.email);

const NOTIFICATION_FRQUENCY_OPTIONS = [
    {
        id: LoginNotificationFrequecies.FIRST_LOGIN,
        name: "Send Email On First Login",
    },
    {
        id: LoginNotificationFrequecies.ALL_LOGIN,
        name: "Send Email On All Login",
    },
];

const UPLOAD_BUCKET = "netlife-company-1";
const UPLOAD_FIELDS: Array<keyof IUser> = ["logoUrl"];
type UploadsPendingType = Partial<Record<keyof IUser, File>>;

const AddEditUser: React.FC<Props> = (props: Props) => {
    const { onSuccess, onCancel, action, initialData } = props;
    const [updateItem, { error }] = useUpdateUser();
    const isOpen = useMemo(
        () => action === "add" || (action === "edit" && initialData != null),
        [action, initialData]
    );
    const { data: nestedFields, isLoading: fieldsLoading } =
        useUserNestedFields(isOpen);
    const { data: permissionSetData } = useAllPermissionSet();
    const overrideFix = useCompanyOverride();
    const { data: loggedInCompany } = useCompany();
    const { data: loggedInUser } = useUserProfile();

    const { data: usersList } = useNotifyUserOptions(overrideFix);
    const disabled = useMemo(
        () => fieldsLoading || nestedFields == null,
        [fieldsLoading, nestedFields]
    );
    const form = useEnhancedForm<IUser>({
        defaultValues: {
            ...initialData,
            loginNotificationFrequencyOption:
                NOTIFICATION_FRQUENCY_OPTIONS.find(
                    (type) =>
                        type.id === initialData?.loginNotificationFrequency
                ),
            notifyUsers: (initialData?.notifyUsersEmail as string[])?.map(
                (email) => usersList?.find((type) => type.email === email)
            ) as IUser[],
        },
        selectOptionsPairs: [
            ...(nestedFields || []),
            ["loginNotificationFrequencyOption", NOTIFICATION_FRQUENCY_OPTIONS],
        ] as SelectOptionsPair<IUser>[],
        disabled,
        rules: {
            email: {
                pattern: {
                    value: /[^@]+@.{2,}/,
                    message: "Invalid email",
                },
            },
        },
    });

    useEffect(() => {
        if (initialData && nestedFields) {
            let rolesList = nestedFields?.find((l: any) => l[0] === "roles");
            let roles = (initialData?.roles as string[])?.map(
                (sp) =>
                    rolesList && rolesList[1].find((s: any) => s.name === sp)
            ) as any[];
            form.setValue("roles", roles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nestedFields, initialData]);

    const { watch } = form;

    const [uploadsPending, setUploadsPending] = useState(
        {} as UploadsPendingType
    );

    const uploadFieldValues = watch(UPLOAD_FIELDS);
    const selectedRoles = watch("roles") as string[];

    const selectedNotificationFrequency = watch(
        "loginNotificationFrequencyOption"
    ) as ILoginNotificationFrequencyOption;

    const isSuperAdmin = useIsSuperAdmin();
    const isExternalAdmin = loggedInUser?.data?.roles?.find((r: any) =>
        r?.includes("Admin")
    );

    // useEffect(() => {
    //     if (isExternalAdmin) {
    //         console.log("going to set user type manually for external client");
    //         const userType = { id: 3, name: "Active External Client" };
    //         form.setValue("type", userType);
    //     }
    // }, [isExternalAdmin, form]);

    const [resetPassword, setResetPassword] = useState(action === "add");

    const handleResetPassword = useCallback(() => {
        setResetPassword(true);
    }, []);

    const clearForm = useCallback(() => {
        form.reset();
        setResetPassword(false);
    }, [form]);

    const onReset = useCallback(() => {
        clearForm();
        onCancel();
    }, [clearForm, onCancel]);

    // const softwarePackages = initialData?.company?.softwarePackages;
    const softwarePackages = loggedInCompany?.softwarePackages;
    const permissionSet = permissionSetData?.data;

    let allRoles = nestedFields?.find((l: any) => l[0] === "roles");
    const collectedRoles = permissionSet
        ?.filter((permission: any) =>
            softwarePackages?.includes(permission.softwarePackage)
        )
        .map((permission) => permission.role);

    const ALL_ROLES =
        allRoles &&
        allRoles[1]
            ?.map((role: any) => {
                if (isSuperAdmin) {
                    return role;
                } else if (collectedRoles?.includes(role?.name)) {
                    return role;
                }
            })
            ?.filter((r: any) => r);

    const onSubmit = useCallback(
        async (data: IUser, e: any) => {
            if (typeof e.preventDefault === "function") {
                e.preventDefault();
            }
            if (typeof e.stopPropagation === "function") {
                e.stopPropagation();
            }
            if (e.target.id !== "userForm") {
                // Ignore submission if it's from the child form
                return;
            }
            try {
                form.clearError();
                // upload profile picture
                const uploadPendingKeys = Object.keys(uploadsPending) as Array<
                    keyof UploadsPendingType
                >;
                const uploadedUrls = await Promise.all(
                    uploadPendingKeys.map(async (field) => {
                        // Image has been deleted, not uploaded so need to clear the field as null
                        if (uploadsPending[field] == null) {
                            return null;
                        }

                        const file = uploadsPending[
                            field as keyof UploadsPendingType
                        ] as File;
                        const hash = await fileHash(file);
                        const nameParts = file.name.split(".");
                        const ext =
                            nameParts.length > 1
                                ? nameParts[nameParts.length - 1]
                                : "";
                        const key = `${hash}.${ext}`;
                        return await signedFileUpload(UPLOAD_BUCKET, key, file);
                    })
                );
                const uploadedFields = Object.fromEntries(
                    uploadPendingKeys.map((key, index) => [
                        key,
                        uploadedUrls[index],
                    ])
                );
                const finalSoftwarePackages = data?.company
                    ? data?.company?.softwarePackages
                    : softwarePackages;
                // setting up DEFAULT GROUP if no groups selected
                let groupsArray: any = [];
                groupsArray = nestedFields?.filter((n) => n[0] === "groups")[0];
                let defaultGroup =
                    groupsArray.length > 1 &&
                    groupsArray[1].filter(
                        (g: { id: string }) =>
                            g.id === "3deeedb5-497a-4178-beb6-ea299a3ca2b0"
                    );
                // extract userGroups from permissionSet
                let validRoles = selectedRoles?.map((role: any) => role?.name);
                validRoles = validRoles ? validRoles : [];
                let filteredUserGroups = permissionSet
                    ?.filter(
                        (permission: any) =>
                            finalSoftwarePackages?.includes(
                                permission.softwarePackage
                            ) && validRoles.includes(permission.role)
                    )
                    .map((permission) => permission.userGroup);
                // Filter groups based on userGroups
                let filteredGroups =
                    groupsArray.length > 1 &&
                    groupsArray[1].filter((group: any) =>
                        filteredUserGroups?.includes(group.name)
                    );
                // set default group when nothing found
                if (initialData?.groups?.find((g) => g?.name === "Admins")) {
                    data.groups = initialData?.groups;
                } else if (filteredGroups?.length === 0) {
                    data.groups = defaultGroup;
                } else {
                    data.groups = filteredGroups;
                }
                // if (!data.groups || data.groups.length === 0)
                //     data.groups = defaultGroup;
                await updateItem({
                    data: {
                        ...initialData,
                        ...data,
                        ...processFormData(data as IUserFormData),
                        notifyUsersEmail: data.notifyUsers?.map((approver) => {
                            return (approver as any).email;
                        }),
                        roles: selectedRoles?.map((r: any) => r?.name),
                        loginNotificationFrequency:
                            selectedNotificationFrequency?.id,
                        ...uploadedFields,
                    } as IUser,
                    create: action === "add",
                });
                onSuccess();
            } catch (error) {
                form.setError("submit", error as string);
            }
        },
        [
            form,
            nestedFields,
            updateItem,
            selectedRoles,
            permissionSet,
            softwarePackages,
            uploadsPending,
            initialData,
            selectedNotificationFrequency,
            action,
            onSuccess,
        ]
    );

    const clearImageField = useCallback(
        (field: keyof UploadsPendingType) => {
            form.control.setValue(field, undefined);
            setUploadsPending((uploads) => ({
                ...uploads,
                [field]: undefined,
            }));

            // Weird behavior in react-hook-form where watch provides the default value even when setValue is used
            // TODO: Look into why this occurs and make general fix in useEnhancedForm
            const defaultValRef = form.control.defaultValuesRef.current as
                | object
                | undefined;
            if (defaultValRef != null) {
                form.control.defaultValuesRef.current = {
                    ...defaultValRef,
                    [field]: undefined,
                };
            }
        },
        [form]
    );

    // useEffect(() => {
    //     if (request?.approversId?.length) {
    //         const approvers =
    //             request.approversId.map((approver) =>
    //                 approverDropdownOptions?.find(
    //                     (option) => option.id === approver
    //                 )
    //             ) ?? [];
    //         form.setValue("quotationApprovers", approvers);
    //     }
    // }, [approverDropdownOptions, form, request?.approversId]);

    return (
        <Modal
            key={`${action}-${initialData?.id}`}
            isOpen={isOpen}
            fade={false}
            size="lg"
            onExit={clearForm}
        >
            <ModalHeader
                style={{ background: "white", border: 0, padding: "0.5rem" }}
            />
            <ToastContainer position="top-right" draggable />
            <form
                id="userForm"
                onSubmit={form.handleSubmit(onSubmit)}
                autoComplete="off"
            >
                <ModalBody className="pt-1 px-4">
                    <div className="form-row">
                        <div className="col-6 form-group">
                            <FormInput
                                id="email"
                                label="Email"
                                form={form}
                                required
                            />
                        </div>
                        <div className="col-6 form-group">
                            <FormInput
                                id="designation"
                                label="Designation"
                                form={form}
                                disabled={!isSuperAdmin && !isExternalAdmin}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-6 form-group">
                            <FormInput
                                id="firstName"
                                label="First Name"
                                form={form}
                                required
                            />
                        </div>
                        <div className="col-6 form-group">
                            <FormInput
                                id="lastName"
                                label="Last Name"
                                form={form}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        {isSuperAdmin && (
                            <div className="col-4 form-group">
                                <FormSelectField
                                    id="type"
                                    label="User Type"
                                    required
                                    form={form}
                                />
                            </div>
                        )}
                        <div className="col-4 form-group">
                            <FormInput
                                id="department"
                                label="Department"
                                form={form}
                                disabled={!isSuperAdmin && !isExternalAdmin}
                            />
                        </div>
                        <div className="col-4 form-group">
                            {resetPassword ? (
                                <FormInput
                                    id="temporaryPassword"
                                    type="password"
                                    label="Change Password"
                                    required={action === "add"}
                                    minLength={5}
                                    placeholder={
                                        action === "add"
                                            ? undefined
                                            : "Leave blank to remain unchanged..."
                                    }
                                    autoComplete="new-password"
                                    form={form}
                                />
                            ) : (
                                <Fragment>
                                    <Label>Change Password</Label>
                                    <div>
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-transition"
                                            onClick={handleResetPassword}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                    {(isSuperAdmin || isExternalAdmin) && (
                        <div className="form-group">
                            <FormCheckbox
                                id="enabled"
                                label="Enabled"
                                big
                                form={form}
                            />
                        </div>
                    )}
                    <div className="form-row">
                        <div className="col-4 form-group">
                            <FormInput
                                id="phoneNumber"
                                label="Phone (with country code)"
                                form={form}
                            />
                        </div>
                        <div className="col-4 form-group">
                            <FormInput id="city" label="City" form={form} />
                        </div>
                        <div className="col-4 form-group">
                            <FormSelectField
                                id="country"
                                label="Country"
                                form={form}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-4 form-group">
                            <FormCheckbox
                                id="shouldNotifyOverEmail"
                                label="Notifications via Email"
                                defaultValue={true}
                                big
                                form={form}
                            />
                        </div>
                        <div className="col-4 form-group">
                            <FormCheckbox
                                id="shouldNotifyOverSms"
                                label="Notifications via SMS"
                                big
                                form={form}
                            />
                        </div>
                        <div className="col-4 form-group">
                            <FormCheckbox
                                id="shouldNotifyOverWhatsApp"
                                label="Notifications via Whatsapp"
                                big
                                form={form}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <Label className="ml-1">Profile Picture</Label>
                        <div className="col-12 form-group">
                            {!uploadFieldValues.logoUrl ||
                            uploadsPending.logoUrl ? undefined : (
                                <React.Fragment>
                                    <div
                                        style={{
                                            width: "auto",
                                            border: "1px solid #ced4da",
                                            height: 300,
                                            background: "#eee",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            backgroundImage: `url('${uploadFieldValues.logoUrl}')`,
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-square btn-danger col"
                                        onClick={() => {
                                            clearImageField("logoUrl");
                                        }}
                                    >
                                        Delete
                                    </button>
                                </React.Fragment>
                            )}
                            <FileDrop
                                onDropAccepted={([file]) =>
                                    setUploadsPending((uploads) => ({
                                        ...uploads,
                                        logoUrl: file,
                                    }))
                                }
                                onRevert={() => {
                                    setUploadsPending((uploads) => ({
                                        ...uploads,
                                        logoUrl: undefined,
                                    }));
                                }}
                                onDropRejected={() =>
                                    toast.error(
                                        "Please upload the profile picture in correct format (jpeg, png, gif).",
                                        {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 3000,
                                            className: "mt-5 mr-2",
                                        }
                                    )
                                }
                                accept={IMAGE_TYPES}
                                width="auto"
                                height={300}
                                previewImage
                                disabled={disabled}
                                buttonOnly={!!uploadFieldValues.logoUrl}
                                buttonText={"Upload Profile Picture"}
                            />
                        </div>
                    </div>
                    {/* <div className="row col-12 form-row justify-content-between form-group border rounded p-3 bg-light">
                        <div className="col-6">
                            <FormSelectField
                                id="loginNotificationFrequencyOption"
                                label="Login Notification Frequency"
                                // isMulti
                                form={form}
                            />
                        </div>
                        <div className="col-6">
                            <FormSelectField
                                id="notifyUsers"
                                label="Notify Login Activity to "
                                form={form}
                                isMulti
                                getOptionLabel={nameLens}
                                getOptionValue={idLens}
                            />
                        </div>
                    </div> */}
                    {isSuperAdmin && (
                        <Fragment>
                            <div className="form-group">
                                <FormSelectField
                                    id="company"
                                    label="Company (Main company which user belongs to)"
                                    form={form}
                                />
                            </div>

                            <div className="form-group">
                                <FormSelectField
                                    id="otherAssociatedCompanies"
                                    label="Other Associated Companies"
                                    form={form}
                                    isMulti
                                />
                            </div>

                            {/* <div className="form-group">
                                <FormSelectField
                                    id="groups"
                                    label="Groups"
                                    isMulti
                                    form={form}
                                />
                            </div> */}
                        </Fragment>
                    )}
                    <div
                        className="form-row"
                        hidden={!isSuperAdmin && !isExternalAdmin}
                    >
                        <div className="col-4 col-md form-group">
                            <FormSelectField
                                id="roles"
                                label="Roles"
                                isMulti
                                form={form}
                                options={ALL_ROLES}
                            />
                        </div>
                    </div>
                    {error != null ? (
                        <div className="form-row">
                            <div className="col text-danger">
                                An error occured.
                            </div>
                        </div>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-outline-secondary btn-transition"
                        onClick={onReset}
                        type="button"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary btn-transition"
                        type="submit"
                    >
                        {action === "add" ? "Create User" : "Update User"}
                    </button>
                </ModalFooter>
            </form>
        </Modal>
    );
};

export default AddEditUser;
