import { addCompanyIntercept, gatewayAuthErrorRedirect } from "../gateway";
import axiosBase, { AxiosResponse } from "axios";
import {
    Paginated,
    Sort,
    SortedPaginatedQueryParams,
} from "../sortedPaginated";
import { QueryOptions, queryCache, useMutation, useQuery } from "react-query";
import { useQueryWrapper } from "../reactQueryWrapper";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";

export const axios = axiosBase.create({
    baseURL: `${process.env.REACT_APP_API_ROOT}/services/netlifecoreservice/api`,
    withCredentials: true,
    maxRedirects: 0,
});
addCompanyIntercept(axios);
axios.interceptors.response.use((res) => res, gatewayAuthErrorRedirect);

export interface IPermissionSet {
    id: number;
    userGroup: string;
    role: number;
    softwarePackage: string;
}

interface Params extends SortedPaginatedQueryParams<IPermissionSet> {
    search?: string;
    filters?: string;
}

const PERMISSION_SET_PARAMS: Params = {
    sorts: {},
    pageIndex: 0,
    pageSize: 20,
};

const sortedPaginated = (res?: AxiosResponse<IPermissionSet[]>) =>
    ({
        total: Number(res?.headers["x-total-count"] || res?.data?.length || 0),
        data: res?.data,
    } as Paginated<IPermissionSet>);

const prepareQuery = (params: Params) => {
    const param = {
        ...PERMISSION_SET_PARAMS,
        ...params,
        sorts: {
            ...PERMISSION_SET_PARAMS.sorts,
            ...params.sorts,
        },
    };
    const sorts = [...Object.entries(param.sorts), ["id", Sort.Ascending]]
        .filter((sort) => sort[1] != null)
        .map((sort) => "sort=" + sort.join(","))
        .join("&");

    return `page=${param.pageIndex}&size=${param.pageSize}&${sorts}${
        params.search != null && params.search.length > 0
            ? "&group.contains=" + encodeURIComponent(params.search)
            : ""
    }${params.filters ? "&" + param.filters : ""}`;
};

const PERMISSION_SET_QUERY = "permissionSetValues";
const PERMISSION_SET_QUERY_EXPIRE_TIME = 5000;

const updateRequest = ({
    add,
    update,
    remove,
}: {
    add: any[];
    update: any[];
    remove: any[];
}) => {
    const requests: any[] = [];
    remove.forEach((item) => {
        if (item == null) return;
        requests.push(axios.delete(`permission-set/${item}`));
    });
    add.forEach((item) => {
        if (item == null) return;
        requests.push(axios.post(`permission-set`, item));
    });
    update.forEach((item) => {
        if (item == null) return;
        requests.push(axios.put(`permission-set/${item.id}`, item));
    });
    return axiosBase.all(requests);
};
export const useUpdatePermissionSet = () =>
    useMutation(updateRequest, {
        onSettled: () => {
            queryCache.refetchQueries(PERMISSION_SET_QUERY);
        },
        useErrorBoundary: false,
        throwOnError: false,
        onError: (error: any) => {
            if (error?.response) {
                toast.error(error?.response?.data?.detail, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000,
                    className: "mt-5 mr-2",
                });
                return;
            }
        },
    });

const request = () => axios.get<IPermissionSet[]>("all-permission-set");
export const useAllPermissionSet = () =>
    useQuery(PERMISSION_SET_QUERY, request, {
        retry: 1,
        staleTime: PERMISSION_SET_QUERY_EXPIRE_TIME,
    });

export const usePermissionSet = (
    params = PERMISSION_SET_PARAMS,
    options?: QueryOptions<Paginated<IPermissionSet>>
) => {
    const query = useMemo(() => prepareQuery(params), [params]);
    const request = useCallback(
        () =>
            axios
                .get<IPermissionSet[]>(`permission-set?${query}`)
                .then(sortedPaginated),
        [query]
    );
    return useQueryWrapper<Paginated<IPermissionSet>, any>(
        [PERMISSION_SET_QUERY, { query }],
        request,
        {
            ...options,
            retry: 1,
            staleTime: PERMISSION_SET_QUERY_EXPIRE_TIME,
        }
    );
};
