export const DASHBOARD_VIEW_ROUTE = "/dashboards";
export const INVENTORY_VIEW_ROUTE = "/inventory";
export const INVENTORY_IMPORT_ROUTE = "/inventory-import";
export const INVENTORY_AGING_ROUTE = "/inventory-aging";
export const VALUE_ANALYSIS_ROUTE = "/network-analysis";
export const INVENTORY_ANALYTICS_ROUTE = "/inventory-analytics";
export const INVENTORY_REQUEST_ROUTE = "/requests";
export const MY_INVENTORY_REQUEST_ROUTE = "/my-requests";
export const MY_QUOTATIONS_ROUTE = "/my-quotations";
export const INVENTORY_UPLOAD_REQUEST_ROUTE = "/request-analysis";
export const UPLOAD_REQUEST_ROUTE = "/Upload-request-analysis";
export const SUPPORT_REQUEST_ROUTE = "/Support-request-analysis";
export const MARKET_AVAILABILITY_ROUTE = "/market-availability";
export const WATCHABLE_INVENTORY_ITEMS_ROUTE = "/wishlisted-parts";
export const QUOTATIONS_ROUTE = "/quotes";
export const FIXED_ASSETS_ROUTE = "/fixed-assets";
export const KITS_ROUTE = "/kits";
export const SYSTEM_CONFIGURATION_ROUTE = "/configurations-systems";

export const ITEM_MASTER_ROUTE = "/item-master";
export const ITEM_MASTER_IMPORT_ROUTE = "/item-master-import";
export const ITEM_MASTER_UPDATE_ROUTE = "/item-master-update";
export const OEM_ROUTE = "/oems";
export const SYSTEM_CATEGORY_ROUTE = "/system-categories";
export const ITEM_CATEGORY_TYPE_ROUTE = "/item-category-type";
export const SYSTEM_TYPE_ROUTE = "/system-type";
export const ITEM_CATEGORY_ROUTE = "/item-category";
export const FREQUENCY_ROUTE = "/frequencies";
export const TECHNOLOGY_ROUTE = "/technologies";
export const VOLTAGE_ROUTE = "/voltages";
export const CHARGER_ROUTE = "/chargers";
export const STORAGE_ROUTE = "/storages";
export const UOM_ROUTE = "/uom";
export const ITEM_CLASS_ROUTE = "/item-class";
export const ASSET_CLASS_ROUTE = "/asset-class";
export const ITEM_CLASS_TYPE_ROUTE = "/item-class-type";
export const DOCUMENT_ROUTE = "/documents";

export const EXCHANGED_DEFECTIVE_PARTS_ROUTE = "/1-4-1-parts";
export const DEFECTIVE_PART_ANALYSIS_ROUTE = "/defective-part-analyses";
export const RMA_TRACKER_ROUTE = "/rma-tracker";
export const PURCHASED_ITEMS_ROUTE = "/purchases";
export const REPLACED_INVENTORY_ITEMS_ROUTE = "/replaced-items";
export const HARDWARE_SUPPORT_ANALYSIS_ROUTE = "/hardware-support-analysis";
export const ASSET_VALUE_DEFAULTS_ROUTE = "/asset-value-defaults";
export const INVENTORY_AGING_RULES_ROUTE = "/inventory-aging-configuration";
export const COMPANY_INVENTORY_REQUEST_FIELDS_ROUTE =
    "/inventory-request-fields";

export const USERS_ROUTE = "/setup/users";
export const GROUPS_ROUTE = "/setup/groups";
export const DASHBOARD_SETTING_ROUTE = "/setup/dashboards";
export const MENU_HELP_VIDEOS_ROUTE = "/setup/menu-help-videos";
export const SHIPPING_MATRIX_ROUTE = "/setup/shipping-matrix";
export const PERMISSION_SET_ROUTE = "/setup/permission-set";
export const FEEDBACK_WORDS_ROUTE = "/setup/feedback-words";
export const FEEDBACKS_ROUTE = "/setup/feedbacks";
export const USER_MANUALS_ROUTE = "/setup/manuals";
export const CHANGELOG_ROUTE = "/setup/changelogs";
export const CONTACTS_ROUTE = "/setup/contacts";
export const NOTIFICATION_TYPE_ROUTE = "/setup/notification-type";
export const USER_ACTIVITY_ROUTE = "/setup/users-activities";
export const COMPANY_ROUTE = "/setup/companies";
export const CURRENCY_ROUTE = "/setup/currencies";
export const COMPANY_TYPES_ROUTE = "/setup/company-types";
export const COMPANY_NETWORK_ROUTE = "/setup/company-networks";
export const COMPANY_GROUP_ROUTE = "/setup/company-groups";
export const WAREHOUSE_ROUTE = "/setup/warehouses";
export const COUNTRY_ROUTE = "/setup/countries";
export const SOFTWARE_PACKAGES_ROUTE = "/setup/software-packages";
export const ROLES_ROUTE = "/setup/roles";
export const SITE_TYPES_ROUTE = "/setup/site-types";
export const SITES_ROUTE = "/setup/sites";
