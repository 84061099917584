import { ICompany } from "./companies.types";
import { ICountry } from "./countries.types";
import { IGroup } from "./groups.types";

export interface IUserTYpe {
    id: number;
    name: string;
}
export interface IUser {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    enabled?: boolean;
    groups?: IGroup[];
    company?: ICompany;
    temporaryPassword?: string;
    type?: IUserTYpe;
    otherAssociatedCompanies: ICompany[];
    designation: string;
    department: string;
    country: ICountry;
    city?: string;
    phoneNumber?: string;
    shouldNotifyOverEmail?: boolean;
    shouldNotifyOverSms?: boolean;
    shouldNotifyOverWhatsApp?: boolean;
    notifyUsers?: (IUser | undefined)[];
    notifyUsersEmail?: string[];
    roles?: string[];
    loginNotificationFrequencyOption?: ILoginNotificationFrequencyOption;
    loginNotificationFrequency?: string;
    analyticsCookieConsent?: string;
    logoUrl?: string;
}

export interface IUserActivity {
    type: string;
    email: string;
    time: string;
    name: string;
    ipAddress: string;
}

export enum LoginNotificationFrequecies {
    FIRST_LOGIN = "First Login",
    ALL_LOGIN = "All Login",
}

export interface ILoginNotificationFrequencyOption {
    id: LoginNotificationFrequecies;
    name: string;
}

export interface IUserProcessed {}

export interface IUserFormData
    extends Omit<IUser, keyof IUserProcessed>,
        IUserProcessed {}

export type IUserKeys = Extract<keyof IUser, string>;
