import React, { SyntheticEvent, useEffect, useState } from "react";
import "./index.scss";

enum Mode {
    DARK = "dark",
    LIGHT = "light",
}

interface ModeProperties {
    theme: Mode;
    ball: BallProperties;
    box: BoxProperties;
}

interface ToggleComponentProperties {
    style: string;
}

interface BoxProperties extends ToggleComponentProperties {}
interface BallProperties extends ToggleComponentProperties {}

const switchProperties: Array<ModeProperties> = [
    {
        theme: Mode.LIGHT,
        ball: {
            style: "transform:translatex(100%);",
        },
        box: {
            style: "background-color:#a9a9a9;",
        },
    },
    {
        theme: Mode.DARK,
        ball: {
            style: "transform:translatex(0%);",
        },
        box: {
            style: "background-color:#ffffff;",
        },
    },
];

const DarkLightToggler: React.FC = () => {
    const preferredMode = localStorage.getItem("mode") as Mode;
    const [isChecked, setIsChecked] = useState<boolean>(
        preferredMode === Mode.LIGHT
    );

    useEffect(() => {
        const currentMode = isChecked ? Mode.LIGHT : Mode.DARK;
        const newMode: ModeProperties = switchProperties.find(
            (switchType) => switchType.theme === currentMode
        ) as ModeProperties;
        document
            .querySelector(".box")
            ?.setAttribute("style", newMode.box.style);
        document
            .querySelector(".ball")
            ?.setAttribute("style", newMode.ball.style);
        document.documentElement.setAttribute("data-theme", newMode.theme);
        localStorage.setItem("mode", newMode.theme);
    }, [isChecked]);

    const handleCheckboxChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        setIsChecked(target.checked);
    };
    return (
        <div className="toggler">
            <div className="btn" style={{ lineHeight: 1.1 }}>
                <span
                    style={{
                        color: "white",
                        verticalAlign: "top",
                        marginRight: "10px",
                    }}
                >
                    Theme
                </span>
                <input
                    type="checkbox"
                    name="check"
                    id="check"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="check">
                    <div className="box">
                        <div className="ball"></div>
                    </div>
                </label>
            </div>
        </div>
    );
};

export default DarkLightToggler;
